let awsRegion = 'eu-west-1';

export default {
 api_urls: {
   reporting: 'https://wyrwiz06g2.execute-api.eu-west-1.amazonaws.com/api',
   geojson: 'https://9xtr97a1di.execute-api.eu-west-1.amazonaws.com/api',
   overlays: 'https://7nq3497x1h.execute-api.eu-west-1.amazonaws.com/api',
 },
 google_api_url: 'AIzaSyDI0B09O3FFEn1-usWMdtJAzat9O7SbvR4',
 main_company_term_id: 6,
 crm_base_url: 'https://crm.staging.macquarie.forwoodsafety.com',
 forwood_id_url: 'https://id.staging.macquarie.forwoodsafety.com?redirect_uri=https://geoeditor.staging.macquarie.forwoodsafety.com',
 custom_tile_url: 'https://mapoverlays.geoeditor.staging.macquarie.forwoodsafety.com',
 Auth: {
   userPoolId: 'eu-west-1_fzVhR9Mmt',
   userPoolWebClientId: '1sevk5trpjegn28qmtdgv5cdlq',
   cookieStorage: {
     domain: '.staging.macquarie.forwoodsafety.com',
     secure: true,
   },
 },
 intercom_enabled: 'True',
 env: 'staging'
};
